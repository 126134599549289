import axios from "axios";
import {ref} from 'vue';

interface IUser {
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
}

export const users = ref<IUser[]>();
export const useUsers = () => {
  const fetchUsers = async () => {
    const { data } = await axios.get<{
      users: IUser[];
    }>("/admin/users.json");
    users.value = data.users;
    return data.users;
  };

  return {
    fetchUsers,
    users,
  };
};
