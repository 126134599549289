import { createApp } from "vue";
import { createRouter, createWebHistory, RouterView } from "vue-router";
import UsersPage from "@/scripts/admin/pages/UsersPage.vue";

const container = document.getElementById("app");

const routes = [
  {
    path: "/admin/users",
    component: UsersPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const mountApp = (el: HTMLElement) => {
  const app = createApp(RouterView);
  app.use(router);
  return app.mount(el);
};

if (container) mountApp(container);
console.log(container);
