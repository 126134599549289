<template>
  <ul v-if="users">
    <RecycleScroller
      page-mode
      class="scroller"
      :items="users"
      :item-size="32"
      key-field="id"
      v-slot="{ item, index }"
    >
      <li class="user">{{ index }}. {{ item.email }}</li>
    </RecycleScroller>
  </ul>
  <span v-else>Loading users...</span>
</template>

<script lang="ts" setup>
import { useUsers } from "../api/users";
import { onMounted } from "vue";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
const { fetchUsers, users } = useUsers();
console.log("users", users);
onMounted(() => {
  fetchUsers();
});
</script>

<style scoped></style>
