import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";
import type { App } from "vue";
import TeamPlansSelector from "../components/TeamPlansSelector.vue";

export default class extends Controller {
  app?: App;
  connect() {
    const el = this.element as HTMLElement;
    this.app = createApp(TeamPlansSelector, { url: el.dataset.url });
    this.app.mount(this.element);
  }

  public disconnect() {
    super.disconnect();
    this.app?.unmount();
  }
}
