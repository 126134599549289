import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";
import type { App } from "vue";
import ConditionFieldset from "../components/ConditionFieldset.vue";

export default class extends Controller {
  app?: App;
  connect() {
    console.info("ConditionFieldsetController#connect");
    const el = this.element as HTMLElement;
    this.app = createApp(ConditionFieldset, { url: el.dataset.url });
    this.app.mount(this.element);
  }

  public disconnect() {
    super.disconnect();
    this.app?.unmount();
  }
}
