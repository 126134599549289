<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-[1300]" @close="closeModal">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-out duration-150"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-in duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/50" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-150"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-150"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
          >
            <DialogPanel
              class="rounded-sm relative bg-white px-4 pt-5 pb-4 text-left shadow-2xl shadow-black/50 transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <DialogTitle as="h3"> Conditions </DialogTitle>
              <div>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th>Rules</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(condition, index) in conditions"
                      class="border-solid border-gray-300 border-1"
                      :key="`${condition.resource}${condition.field}${condition.operator}`"
                    >
                      <td class="p-1">
                        {{ condition.resource }}.{{ condition.field }}
                        {{ condition.operator }} {{ condition.value }}
                      </td>
                      <td>
                        <a
                          class="text-red-700"
                          href="#"
                          @click.prevent="removeCondition(condition, index)"
                        >
                          <XMarkIcon class="w-3 h-3"></XMarkIcon>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a
                  href="#"
                  class="underline decoration-amber-600 decoration-dashed decoration-2"
                  v-if="!showForm"
                  @click.prevent="showForm = true"
                  >Add condition</a
                >
                <hr />
                <form v-if="showForm" class="mt-2">
                  <div>
                    <select
                      name="resource"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      @input="(e) => changeResource(e.target.value)"
                    >
                      <option disabled :value="undefined">
                        Choose a resource
                      </option>
                      <optgroup
                        v-for="(records, base) in availableRecords"
                        :label="base"
                        :key="base"
                      >
                        <option
                          v-for="record in records"
                          :value="`${base}.${record}`"
                          :key="record"
                        >
                          {{ record }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                  <template
                    v-if="newCondition.resource && availableFields.length > 0"
                  >
                    <div class="flex mt-5 flex-row content-between">
                      <!--                        <FieldsList-->
                      <!--                          v-model="selectedField"-->
                      <!--                          :fields="availableFields"-->
                      <!--                        ></FieldsList>-->

                      <select
                        v-model="selectedField"
                        name="field"
                        required
                        class="pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option disabled :value="undefined">
                          Choose a field
                        </option>
                        <option
                          v-for="field in availableFields"
                          :value="field"
                          :key="field"
                        >
                          {{ field.name }}[{{ field.type }}]
                        </option>
                      </select>
                      <select
                        v-if="selectedField"
                        v-model="newCondition.operator"
                        name="operator"
                        required
                        class="ml-2 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option
                          v-for="operator in operators"
                          :key="operator"
                          :value="operator"
                        >
                          {{ operator }}
                        </option>
                      </select>

                      <template v-if="newCondition.field && selectedField">
                        <div v-if="false" class="mt-4">
                          <fieldset class="mt-4">
                            <legend class="sr-only">Rule type</legend>
                            <div
                              class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                            >
                              <div
                                v-for="valueType in [
                                  'boolean',
                                  'integer',
                                  'string',
                                ]"
                                :key="valueType"
                                class="flex items-center"
                              >
                                <input
                                  disabled="disabled"
                                  :id="`switch${valueType}`"
                                  name="value_type"
                                  type="radio"
                                  v-model="newCondition.value_type"
                                  :value="valueType"
                                  class="border-solid disabled focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
                                />
                                <label
                                  :for="`switch${valueType}`"
                                  class="cursor-pointer ml-3 block text-sm font-medium text-gray-700"
                                >
                                  {{ valueType }}
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div
                          class="w-1/5 ml-2 flex justify-center align-items-center"
                          v-if="selectedField.type === 'boolean'"
                        >
                          <Switch
                            v-model="newCondition.boolean_value"
                            :class="[
                              newCondition.boolean_value
                                ? 'bg-cyan-600'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 p-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500',
                            ]"
                          >
                            <span class="sr-only">True?</span>
                            <span
                              aria-hidden="true"
                              :class="[
                                newCondition.boolean_value
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                        </div>
                        <div
                          class="w-1/5 ml-2"
                          v-if="selectedField.type === 'integer'"
                        >
                          <input
                            type="number"
                            name="integer_value"
                            id="integer_field"
                            class="border-solid shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="123"
                            v-model="newCondition.integer_value"
                          />
                        </div>
                        <div
                          class="w-1/5 ml-2"
                          v-if="selectedField.type === 'string'"
                        >
                          <input
                            type="text"
                            name="string_value"
                            id="string_field"
                            class="border-solid shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="My awesome condition"
                            v-model="newCondition.string_value"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                  <div class="mt-3">
                    <button
                      type="button"
                      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:col-start-2 sm:text-sm"
                      @click="saveCondition()"
                    >
                      Save condition
                    </button>
                  </div>
                </form>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
              >
                <button
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  @click="closeModal"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <button
    @click.prevent="isOpen = true"
    class="py-2 px-3 border-none bg-cyan-700 text-white rounded-sm"
  >
    Conditions
  </button>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import axios from "axios";
// import FieldsList from "./FieldsList.vue";
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});
type tValueType =
  | "boolean"
  | "string"
  | "integer"
  | "none"
  | "datetime"
  | "date";
type tOperator =
  | "=="
  | "!="
  | ">"
  | "<"
  | ">="
  | "<="
  | "=~"
  | "is_present"
  | "is_blank";
type Condition = {
  base_source: "event" | "profile" | string;
  resource: string | undefined;
  field: string | undefined;
  value_type?: tValueType;
  operator: tOperator;
  boolean_value?: boolean;
  string_value?: string;
  integer_value?: number;
  value?: string | number | boolean;
  url: string;
};
interface Field {
  name: string;
  type: tValueType;
}
const emptyCondition: Condition = {
  base_source: "event",
  field: undefined,
  resource: undefined,
  value_type: undefined,
  operator: "==",
  boolean_value: false,
  string_value: undefined,
  integer_value: undefined,
  value: undefined,
  url: "",
};
const isOpen = ref(false);
const showForm = ref(false);
const newCondition = reactive<Condition>({ ...emptyCondition });
const availableRecords = ref<{ [key: string]: Array<string> }>();
const availableFields = ref<Array<Field>>([]);
const selectedField = ref<Field>();

const conditions = ref<Array<Condition>>([]);
type tConditionsResponse = {
  automation_action: {
    conditions: Array<Condition>;
  };
};

const operators = computed<Array<tOperator>>(() => {
  switch (selectedField.value?.type) {
    case "boolean":
      return ["=="];
    case "string":
      return ["==", "!=", "=~", "is_present", "is_blank"];
    case "date":
    case "datetime":
      return ["is_present", "is_blank"];
    case "integer":
      return ["==", "!=", ">", ">=", "<", "<=", "is_present", "is_blank"];
    default:
      return [];
  }
});

onMounted(() => {
  axios
    .get<tConditionsResponse>(props.url, {
      headers: { accept: "application/json" },
    })
    .then(({ data: { automation_action } }) => {
      conditions.value = automation_action.conditions;
    });
  axios
    .get<{ [key: string]: Array<string> }>(`${props.url}/available_records`, {
      headers: { accept: "application/json" },
    })
    .then(({ data }) => {
      availableRecords.value = data;
    });
});
watch(selectedField, (v) => {
  if (!v) return;
  newCondition.field = v.name;
  if (["boolean", "integer", "string"].includes(v.type))
    newCondition.value_type = v.type;
  else newCondition.value_type = "none";
});
watch(
  () => newCondition.resource,
  (v) => {
    availableFields.value = [];
    selectedField.value = undefined;
    if (!v) return;
    axios
      .get<Array<Field>>(`${props.url}/available_fields?record=${v}`, {
        headers: { accept: "application/json" },
      })
      .then(({ data }) => {
        availableFields.value = data;
      });
  }
);

function saveCondition() {
  axios
    .post<{ condition: Condition }>(
      `${props.url}/conditions`,
      {
        condition: { ...newCondition },
      },
      {
        headers: { accept: "application/json" },
      }
    )
    .then(({ data }) => {
      conditions.value.push(data.condition);
    })
    .then(() => (showForm.value = false))
    .then(() => Object.assign(newCondition, { ...emptyCondition }));
}

function removeCondition(condition: Condition, index: number) {
  axios.delete<{ condition: Condition }>(condition.url).then(() => {
    conditions.value.splice(index, 1);
  });
}

function closeModal() {
  isOpen.value = false;
  Object.assign(newCondition, { ...emptyCondition });
  showForm.value = false;
  selectedField.value = undefined;
}

function changeResource(base_record: string) {
  if (!base_record) return;

  const [base, resource] = base_record.split(".");
  newCondition.base_source = base;
  newCondition.resource = resource;
}
</script>
