import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row"];
  declare rowTargets: HTMLTableRowElement[];

  highlight(e: MouseEvent): void {
    e.preventDefault();
    const link = e.currentTarget as HTMLElement;
    if (!link.dataset.target) return;
    const target = document.getElementById(link.dataset.target) as HTMLElement;
    if (!target) return;
    target.scrollIntoView({ behavior: "smooth", block: "nearest" });
    if (this.rowTargets.length === 0) return;
    window.requestAnimationFrame(() => {
      this.rowTargets.forEach((row) => {
        row.style.backgroundColor = "";
        row.classList.remove("animate-blink");
      });
      window.requestAnimationFrame(() => {
        target.classList.add("animate-blink");
        target.style.backgroundColor = "var(--color-highlight)";
      });
    });
  }
}
