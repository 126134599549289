<template>
  <button
    class="py-2 px-3 border-none bg-cyan-700 text-white rounded-sm"
    role="button"
    type="button"
    @click.prevent="isOpen = true"
  >
    Assign team
  </button>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-[1300]" @close="isOpen = false">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-out duration-150"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-in duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/50" />
      </TransitionChild>
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-150"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-150"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
          >
            <DialogPanel
              class="rounded-sm relative bg-white px-4 pt-5 pb-4 text-left shadow-2xl shadow-black/50 transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <DialogTitle as="h3"> lingu.com </DialogTitle>
              <div v-if="teams === undefined" class="my-5">
                <span class="text-green-600">
                  <CogIcon class="animate-spin h-7 w-7 float-left mr-1" />
                  Loading available teams...
                </span>
              </div>
              <Listbox v-else as="div" v-model="form.team_id">
                <ListboxLabel
                  class="block text-lg font-bold text-gray-700 mt-4"
                >
                  Select team:
                </ListboxLabel>
                <div class="mt-1 relative">
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                  >
                    <span class="block truncate">
                      {{ selectedTeam ? selectedTeam.name : "Select team" }}
                    </span>
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <chevron-up-down-icon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-md"
                    >
                      <ListboxOption
                        as="template"
                        :value="undefined"
                        v-slot="{ active, selectedTeam }"
                      >
                        <li
                          :class="[
                            active
                              ? 'text-white bg-indigo-600'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedTeam ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            class="text-gray-400"
                          >
                            No team
                          </span>

                          <span
                            v-if="selectedTeam"
                            :class="[
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                      <ListboxOption
                        as="template"
                        v-for="team in teams"
                        :key="team.id"
                        :value="team.id"
                        v-slot="{ active, selectedTeam }"
                      >
                        <li
                          :class="[
                            active
                              ? 'text-white bg-indigo-600'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedTeam ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ team.name }}
                          </span>

                          <span
                            v-if="selectedTeam"
                            :class="[
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <template v-if="form.team_id">
                <div v-if="plans === undefined" class="my-5">
                  <span class="text-green-600">
                    <CogIcon class="animate-spin h-7 w-7 float-left mr-1" />
                    Loading available plans...
                  </span>
                </div>
                <Listbox
                  as="div"
                  v-else-if="plans.length > 0"
                  v-model="form.remote_plan_id"
                >
                  <ListboxLabel
                    class="block text-lg font-bold text-gray-700 mt-4"
                  >
                    Select pricing plan:
                  </ListboxLabel>
                  <div class="mt-1 relative">
                    <ListboxButton
                      class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                    >
                      <span class="block truncate">
                        {{ selectedPlan ? selectedPlan.name : "Select plan" }}
                      </span>
                      <span
                        class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                      >
                        <chevron-up-down-icon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-md"
                      >
                        <ListboxOption
                          as="template"
                          v-for="plan in plans"
                          :key="plan.id"
                          :value="plan.id"
                          v-slot="{ active, selectedPlan }"
                        >
                          <li
                            :class="[
                              active
                                ? 'text-white bg-indigo-600'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedPlan ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              ]"
                            >
                              {{ plan.name }}
                            </span>

                            <span
                              v-if="selectedPlan"
                              :class="[
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
                <div v-else class="my-5">
                  <span class="text-orange-500">
                    No available pricing plans for this team
                  </span>
                  <br />

                  <a
                    :href="`https://admin.lingu.com/teams/${selectedTeam!.remote_id}`"
                    target="_blank"
                  >
                    <span class="text-blue-500 underline">
                      Configure plan in lingu.com
                    </span>
                  </a>
                </div>
                <template v-if="form.remote_plan_id">
                  <div v-if="courses === undefined" class="my-5">
                    <span class="text-green-600">
                      <CogIcon class="animate-spin h-7 w-7 float-left mr-1" />
                      Loading available courses...
                    </span>
                  </div>
                  <Listbox
                    as="div"
                    v-else-if="courses.length > 0"
                    v-model="form.remote_course_id"
                  >
                    <ListboxLabel
                      class="block text-lg font-bold text-gray-700 mt-4"
                    >
                      Select primary course:
                    </ListboxLabel>
                    <div class="mt-1 relative">
                      <ListboxButton
                        class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-md"
                      >
                        <span class="block truncate">
                          {{
                            selectedCourse
                              ? selectedCourse.title
                              : "Select primary course"
                          }}
                        </span>
                        <span
                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                        >
                          <chevron-up-down-icon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>

                      <transition
                        leave-active-class="transition ease-in duration-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions
                          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-md"
                        >
                          <ListboxOption
                            as="template"
                            v-for="course in courses"
                            :key="course.id"
                            :value="course.id"
                            v-slot="{ active, selectedCourse }"
                          >
                            <li
                              :class="[
                                active
                                  ? 'text-white bg-indigo-600'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9',
                              ]"
                            >
                              <span
                                :class="[
                                  selectedCourse
                                    ? 'font-semibold'
                                    : 'font-normal',
                                  'block truncate',
                                ]"
                              >
                                {{ course.title }}
                              </span>

                              <span
                                v-if="selectedCourse"
                                :class="[
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                              >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                  <div v-else class="my-5">
                    <span class="text-orange-500">
                      No available courses for this plan
                    </span>
                  </div>
                </template>
                <div class="mt-4 w-full">
                  <button
                    class="py-2 px-3 w-full bg-green-700 hover:bg-green-600 active:bg-green-500 disabled:bg-gray-300 disabled:cursor-not-allowed text-white rounded-sm"
                    :disabled="btnDisabled"
                    role="button"
                    type="submit"
                    @click="submit"
                  >
                    Save
                  </button>
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <input
    ref="team_id"
    type="hidden"
    name="group[team_id]"
    :value="selectedTeam ? selectedTeam.id : undefined"
  />
  <input
    ref="remote_plan_id"
    type="hidden"
    name="group[remote_plan_id]"
    :value="form.remote_plan_id"
  />
  <input
    ref="remote_course_id"
    type="hidden"
    name="group[remote_course_id]"
    :value="form.remote_course_id"
  />
</template>

<script lang="ts" setup>
import axios from "axios";
import { computed, onMounted, reactive, ref, watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon, CogIcon } from "@heroicons/vue/24/solid";

interface Team {
  id: number;
  name: string;
  remote_id: number;
}

interface Plan {
  id: number;
  name: string;
}

interface Course {
  id: number;
  title: string;
}

interface Form {
  team_id?: number;
  remote_plan_id?: number;
  remote_course_id?: number;
}

const props = defineProps<{ url: string }>();

const isOpen = ref(false);
const teams = ref<Array<Team>>();
const disabled = ref(false);
const selectedTeam = computed(() => {
  if (teams.value === undefined) return;
  return teams.value.find((t) => {
    return t.id === form.team_id;
  });
});
const btnDisabled = computed(() => {
  return (
    !form.team_id ||
    !form.remote_plan_id ||
    !form.remote_course_id ||
    disabled.value
  );
});
const selectedPlan = computed(() => {
  if (plans.value === undefined) return;

  return plans.value.find((t) => {
    return t.id === form.remote_plan_id;
  });
});
const selectedCourse = computed(() => {
  if (courses.value === undefined) return;

  return courses.value.find((t) => {
    return t.id === form.remote_course_id;
  });
});
const plans = ref<Array<Plan>>();
const courses = ref<Array<Course>>();

const form = reactive<Form>({});

onMounted(() => {
  axios
    .get<{ teams: Array<Team> }>(`${props.url}/teams_list`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then(({ data: { teams: loadedTeams } }) => {
      // console.log(loadedTeams);
      teams.value = loadedTeams;
    });
});
watch(
  () => form.team_id,
  (val) => {
    form.remote_plan_id = undefined;
    if (!val) {
      plans.value = [];
      return;
    }
    axios
      .get<{ plans: Array<Plan> }>(`/admin/groups/plans_list?team_id=${val}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then(({ data: { plans: loadedPlans } }) => {
        console.log(loadedPlans);
        plans.value = loadedPlans;
      });
  }
);
watch(
  () => form.remote_plan_id,
  (val) => {
    form.remote_course_id = undefined;
    if (!val) {
      courses.value = [];
      return;
    }
    axios
      .get<{ courses: Array<Course> }>(
        `/admin/groups/courses_list?team_id=${form.team_id}&plan_id=${val}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(({ data: { courses: loadedCourses } }) => {
        console.log(loadedCourses);
        courses.value = loadedCourses;
      });
  }
);

function submit() {
  disabled.value = true;
  axios
    .put(
      props.url,
      { group: form },
      {
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": document.querySelector<HTMLMetaElement>(
            'meta[name="csrf-token"]'
          )!.content,
        },
      }
    )
    .then((response) => {
      console.log(response);
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
      disabled.value = false;
    });
}
</script>
