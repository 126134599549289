import { Controller } from "@hotwired/stimulus";
import consumer from "../admin/channels/consumer";

export default class extends Controller {
  static targets = ["html"];
  declare htmlTarget: HTMLElement;
  declare hasHtmlTarget: boolean;
  declare subscription: any;
  connect(): void {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "Admin::ManagedEditChannel",
        id: this.data.get("id"),
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {
    console.log("Connected");
  }

  _disconnected() {
    console.log("Disconnected");
    this.subscription.unsubscribe();
  }

  _received(data: string) {
    if (this.hasHtmlTarget) this.htmlTarget.innerHTML = data;
    else this.element.innerHTML = data;
  }
}
